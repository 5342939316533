<template>
  <div class="modal fade" id="viewTemplateModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewTemplateModalLabel">View template</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row" v-if="template">
            <div class="col-6">
              <div class="d-flex align-items-center ps-4 mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Name: {{ template.JobData.name }}
                </div>
              </div>
              <div class="d-flex align-items-center ps-4 mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Address: {{ template.JobData.address }}
                </div>
              </div>

              <div class="d-flex align-items-center ps-4 mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Venue: {{ getVenueName }}
                </div>
              </div>
              <div class="d-flex align-items-center ps-4 mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  City: {{ template.JobData.city }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Country: {{ getCountryName }}
                </div>
              </div>
              <div class="d-flex align-items-center mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Entrance: {{ template.JobData.entrance }}
                </div>
              </div>

              <div class="d-flex align-items-center mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Company:
                  {{ template.company_name ? template.company_name : "N/A" }}
                </div>
              </div>
              <div class="d-flex align-items-center mb-2">
                <span class="bullet me-3"></span>
                <div class="text-gray-600 fw-bold fs-7">
                  Created by: {{ template.created_by }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "job-template-modal",
  props: {
    template: {
      type: Object || null,
      required: true
    }
  },
  computed: {
    ...mapGetters("ListsModule", ["countries", 'venues']),
    getCountryName() {
      if(this.countries.length > 0 && this.template.JobData.country !== "") {
        return this.countries.find(country => country.id == this.template.JobData.country).title;
      } else {
        return ""
      }
    },
    getVenueName() {
      if(this.venues.length > 0 && this.template.JobData.venueId !== "") {
        return this.venues.find(venue => venue.id == this.template.JobData.venueId).name;
      } else {
        return ""
      }
    }
  }
});
</script>
