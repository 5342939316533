<template>
  <CreateJobIndex></CreateJobIndex>
</template>

<script>
import { defineComponent } from "vue";
import CreateJobIndex from "@/components/jobs/Create/CreateJobIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "create-job-view",
  components: {
    CreateJobIndex
  },
  mounted() {
    setCurrentPageTitle("Create a job");
  }
});
</script>
