<template>
  <div class="table-responsive">
    <table
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px w-175px px-9">Day</th>
          <th class="w-100px">Shift Start</th>
          <th class="min-w-100px">Shift End</th>
          <th class="min-w-100px">Duration</th>
          <th class="min-w-100px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="shift in shifts" :key="shift.id">
          <tr>
            <td class="px-9">
              <div class="d-flex">
                <el-date-picker
                  name="date"
                  size="large"
                  v-model="shift.startDate"
                  type="date"
                  placeholder="Select start date"
                  format="ddd DD/MM/YYYY"
                  value-format="DD/MM/YYYY"
                ></el-date-picker>
              </div>
            </td>
            <td>
              <input
                v-model="shift.startHour"
                id="startHour_edit"
                class="form-control me-2 w-70px h-40px"
              />
            </td>
            <td>
              <input
                v-model="shift.endHour"
                id="endHour_edit"
                class="form-control me-2 w-70px h-40px"
              />
            </td>

            <td class="text-end">
              <ShiftDurationWidget :shift="shift"></ShiftDurationWidget>
            </td>
            <td>
              <a
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                data-bs-toggle="collapse"
                :href="'#shiftCollapse' + shift.id"
              >
                <span class="svg-icon svg-icon-3">
                  <ActionButtonIcon
                    :dynamicClass="'svg-icon-light-primary'"
                    :svgSize="'svg-icon-3'"
                  ></ActionButtonIcon>
                </span>
              </a>
              <DeleteShiftButton
                @click="handleDeleteShift(shift.id)"
              ></DeleteShiftButton>
            </td>
          </tr>
          <tr>
            <td colspan="12" class="p-0 m-0">
              <div class="collapse in" :id="'shiftCollapse' + shift.id">
                <div class="px-3 mb-4">
                  <BoxShiftSlots
                    :selectedSkills="shift.skills"
                    @booked="handleBookButton($event, shift.id)"
                    :localShiftID="shift.id"
                  ></BoxShiftSlots>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <tr class="border-bottom-2">
          <td
            colspan="5"
            class="bg-hover-secondary"
            data-bs-toggle="modal"
            data-bs-target="#addNewShiftModal"
          >
            <div class="d-flex justify-content-center align-items-center">
              <span class="svg-icon svg-icon-3 svg-icon-primary"
                ><svg
                  version="1.1"
                  viewBox="0 0 24 24"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    xmlns="http://www.w3.org/2000/svg"
                    id="Oval-5"
                    fill="#000000"
                    opacity="0.3"
                    cx="12"
                    cy="12"
                    r="10"
                  ></circle>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    id="Combined-Shape"
                    fill="#000000"
                  ></path></svg
              ></span>
              <span class="text-primary fs-5 ms-2">Add new Shift</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import BoxShiftSlots from "../BoxShiftSlotWithInputs";
import Inputmask from "inputmask";
import ShiftDurationWidget from "@/components/jobs/Widgets/ShiftDuration.vue";
import DeleteShiftButton from "@/components/jobs/Widgets/DeleteShiftButton.vue";
import ActionButtonIcon from "@/components/Icons/ActionButton.vue";


export default defineComponent({
  name: "shift-list",
  components: { BoxShiftSlots, ShiftDurationWidget, DeleteShiftButton, ActionButtonIcon },
  computed: {
    ...mapGetters("JobCreateModule", ["shifts"])
  },
  methods: {
    ...mapActions("JobCreateModule", [
      "setShiftSkills",
      "changeShiftDate",
      "deleteShift"
    ]),
    handleBookButton(skills, shiftKey) {
      this.setShiftSkills({ shiftKey, skills });
    },
    handleDeleteShift(id) {
      this.deleteShift(id);
    }
  },
  beforeUpdate() {
    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#startHour_edit");

    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#endHour_edit");
  }
});
</script>
