<template>
  <ViewTemplateModal
    :template="viewedTemplate"
    @useTemplate="handleUseTemplate"
  ></ViewTemplateModal>
  <AddNewShiftModal
    @newShift="handleAddNewShift"
    :isEditingJob="false"
  ></AddNewShiftModal>
  <div class="row">
    <div class="col-8">
      <SelectClientCard
        class="mb-7"
        @select-company="handleSelectCompany"
        @select-branch="handleSelectBranch"
        :auto-select-values="true"
        ref="selectClientCard"
        id="selectClientCard"
      ></SelectClientCard>
      <CreateShiftsCard></CreateShiftsCard>
      <ConfigureShiftsCard :key="renderKey" class="mb-7"></ConfigureShiftsCard>
      <CreateJobDetails
        :key="jobDetailsKey"
        :is-using-template="isUsingTemplate"
        ref="job_details_card"
        id="job_details_card"
      ></CreateJobDetails>
    </div>
    <div class="col-4">
      <div class="fixed-job-overview">
        <PriceEstimationWidget
          class="mb-5"
          v-if="$can('can_view_price_estimation', 'all')"
        ></PriceEstimationWidget>
        <JobTemplatesCard
          :company-id="company"
          @templateEvent="handleUseTemplate"
          @openTemplateModal="handleTemplateModal"
        ></JobTemplatesCard>
        <JobSummaryCard
          @publish="handlePublish"
          @template="handleTemplate"
          :class="jobTemplates.length > 0 ? 'mt-7' : ''"
        ></JobSummaryCard>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from "vue";
import AddNewShiftModal from "@/components/jobs/Edit/AddNewShiftModal.vue";
import CreateShiftsCard from "@/components/jobs/Create/CreateShiftsCard.vue";
import CreateJobDetails from "@/components/jobs/Create/JobDetailsCard.vue";
import ConfigureShiftsCard from "./ConfigureShiftsCard";
import JobSummaryCard from "@/components/jobs/Create/JobSummaryCard.vue";
import JobTemplatesCard from "@/components/jobs/Create/JobTemplatesCard.vue";
import ViewTemplateModal from "@/components/jobs/Create/Misc/JobTemplateModal.vue";
import PriceEstimationWidget from "@/components/jobs/Create/Misc/PriceEstimatesWidget.vue";
import SelectClientCard from "@/components/jobs/Create/SelectClientCard.vue";
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "create-job-index",
  components: {
    PriceEstimationWidget,
    ViewTemplateModal,
    AddNewShiftModal,
    JobTemplatesCard,
    ConfigureShiftsCard,
    CreateShiftsCard,
    CreateJobDetails,
    JobSummaryCard,
    SelectClientCard
  },
  data: () => ({
    areShiftsValid: false,
    viewedTemplate: null,
    isUsingTemplate: false,
    jobDetailsKey: 0,
    renderKey: 0
  }),
  computed: {
    ...mapGetters("JobCreateModule", [
      "shifts",
      "jobTemplates",
      "hasTemplates",
      "selectedCompany"
    ]),
    company() {
      return this.selectedCompany;
    }
  },
  methods: {
    ...mapActions("ListsModule", ["fetchSingleVenue"]),
    ...mapActions("PriceEstimationModule", ["setClientID", "setBranchesID"]),
    ...mapActions("JobCreateModule", [
      "createJob",
      "resetState",
      "saveTemplate",
      "populateShifts",
      "setSelectedCompany",
      "addSingleShift",
      "setJobData",
      "fetchTemplates"
    ]),
    handleSelectBranch(data) {
      this.setBranchesID(data);
    },
    handleSelectCompany(companyId) {
      const id = companyId && companyId !== "" ? companyId : null;
      this.setSelectedCompany(id);
      this.setClientID(id);
      if (id === null) {
        this.jobDetailsKey++;
      }
    },
    handlePublish: function() {
      this.$refs.selectClientCard
        .validateForm()
        .then(selectClientData => {
          this.$refs.job_details_card
            .validateInputs()
            .then(jobDetailsData => {
              this.setJobData({
                ...selectClientData,
                ...jobDetailsData
              });
              this.createJob()
                .then(() => {
                  this.$router.push("/dashboard");
                })
                .catch(err => {
                  Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: `${err.response.data.message}`,
                    showConfirmButton: false,
                    timer: 800
                  }).then(() => {
                    if (err.response.data.type === "shifts_invalid") {
                      document
                        .getElementById("create-shifts-card")
                        .scrollIntoView({
                          behavior: "smooth",
                          inline: "start"
                        });
                    }
                  });
                });
            })
            .catch(() => {
              document.getElementById("job_details_card").scrollIntoView({
                behavior: "smooth",
                inline: "center"
              });
            });
        })
        .catch(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    handleTemplateModal(data) {
      this.viewedTemplate = data;
    },
    handleAddNewShift(data) {
      this.addSingleShift(data);
    },
    handleUseTemplate(data) {
      this.isUsingTemplate = true;
      this.$refs.job_details_card.populateFieldsFromTemplate(data.JobData);
      this.populateShifts({
        shift: data.shifts,
        userSelectedDate: data.userSelectedDate
      });
      this.$refs.selectClientCard.setInitialData(data.JobData);
      this.isUsingTemplate = false;
      this.renderKey++;
    },
    handleTemplate(name) {
      this.$refs.selectClientCard
        .validateForm()
        .then(selectClientData => {
          this.$refs.job_details_card
            .getDataForTemplate()
            .then(jobDetailsData => {
              const data = {
                ...selectClientData,
                ...jobDetailsData
              };
              this.saveTemplate({ data: data, name: name }).then(() => {
                this.fetchTemplates();
                Swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: "success",
                  title: `Job template saved successfully`,
                  showConfirmButton: false,
                  timer: 800
                });
              });
            })
            .catch(() => {
              document.getElementById("job_details_card").scrollIntoView({
                behavior: "smooth",
                inline: "center"
              });
            });
        })
        .catch(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  },
  beforeUnmount() {
    this.resetState();
  }
});
</script>

<style scoped>
.fit-content {
  height: fit-content;
}

.fixed-job-overview {
  position: fixed;
}

@media screen and (min-width: 992px) {
  .fixed-job-overview {
    min-width: 445px;
  }
}
</style>
