<template>
  <tr>
    <td class="align-middle text-muted mt-1 fw-bold fs-7">
      {{ template.name }}
    </td>
    <td>
      <div class="d-flex justify-content-end">
        <el-button
          round
          size="mini"
          @click="$emit('viewTemplateModal', template)"
          data-bs-target="#viewTemplateModal"
          data-bs-toggle="modal"
          >View</el-button
        >
      </div>
    </td>
    <td class="text-end">
      <div class="d-flex justify-content-end">
        <el-button
          type="success"
          round
          size="mini"
          @click="$emit('useTemplate', template)"
          >Use</el-button
        >
      </div>
    </td>
  </tr>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "job-template",
  props: {
    template: {
      type: Object,
      required: true
    }
  }
});
</script>
