<template>
  <div
    class="d-flex flex-column align-items-center"
    :class="jobTemplates.length > 0 ? 'mt-5' : ''"
  >
    <button
      v-if="!isCreatingTemplate"
      class="btn btn-sm btn-success w-100 mb-4 fs-4 custom-height"
      @click="$emit('publish')"
      :disabled="loading ? true : false"
    >
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        v-if="loading"
      ></div>
      Publish Job
    </button>
    <button
      class="btn btn-sm btn-primary w-100"
      :class="isCreatingTemplate ? 'fs-4 custom-height' : ''"
      @click="handleTemplate"
      :disabled="isSavingTemplate ? true : false"
    >
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        v-if="isSavingTemplate"
      ></div>
      Save template
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default defineComponent({
  name: "job-summary-card",
  computed: {
    ...mapGetters("JobCreateModule", [
      "loading",
      "isSavingTemplate",
      "jobTemplates"
    ]),
    isCreatingTemplate() {
      if (this.$route.query.template) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    handleTemplate() {
      const { value: name } = Swal.fire({
        title: "Enter template name",
        input: "text",
        confirmButtonColor: "green",
        confirmButtonText: "Save",
        showCancelButton: true,
        inputValidator: value => {
          if (!value) {
            return "Name is required";
          }
        }
      }).then(data => {
        if (data.value) {
          this.$emit("template", data.value);
        }
      });
    }
  }
});
</script>
<style scoped>
.custom-height {
  height: 4.5rem;
}
</style>
