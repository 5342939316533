<template>
  <div class="row mt-5">
    <div class="col-6">
      <SkillsDropdown
        :disabled="isFetchingFreqSkills"
        @change="onSkillChange"
      ></SkillsDropdown>
      <div class="table-responsive" v-if="categorySkillId">
        <table
          class="table table-row-dashed table-striped table-row-gray-300 align-middle"
        >
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-50px w-175px px-9">Required skill</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subSkill in subSkills" :key="subSkill.id">
              <td class="px-9">
                {{ subSkill.name }}
              </td>
              <td>
                <div
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-control w-75px form-control-sm"
                    type="number"
                    @keydown.enter="ev => handleEnterKey(ev, subSkill.id)"
                    @change="onChangeQtySubSkill($event, subSkill.id, false)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-6">
      <button class="btn btn-success w-100 h-40px" @click="handleBookButton">
        Book
      </button>
      <div class="table-responsive">
        <table
          class="table table-row-dashed table-striped table-row-gray-300 align-middle"
        >
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-50px w-175px px-9">Required skill</th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subSkill in bookedSkills" :key="subSkill.id">
              <td class="px-9">
                {{ subSkill.name }}
              </td>
              <td>
                <div
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-control w-75px form-control-sm"
                    type="number"
                    :value="subSkill.qty"
                    @change="onChangeQtySubSkill($event, subSkill.id, true)"
                  />
                </div>
              </td>
              <td>
                <a
                  @click.prevent="handleDeleteBlueprintSkill(subSkill)"
                  class="btn btn-icon btn-bg-danger btn-active-color-primary btn-sm"
                >
                  <DeleteButtonIcon
                    :dynamicClass="'svg-icon-white'"
                    :svgSize="'svg-icon-3'"
                  ></DeleteButtonIcon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import SkillsDropdown from "../dropdown/JobCreateSkillsDropdown";
import { mapActions, mapGetters } from "vuex";
import DeleteButtonIcon from "@/components/Icons/DeleteButton.vue";
import _ from "lodash";

export default {
  name: "box-shift-slots",
  components: { SkillsDropdown, DeleteButtonIcon },
  props: {
    selectedSkills: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      categorySkillId: null,
      selectedSubSkills: {},
      bookedSkills: {}
    };
  },
  mounted() {
    if (this.selectedSkills) {
      this.bookedSkills = Object.assign({}, this.selectedSkills);
      this.selectedSubSkills = this.bookedSkills;
    }
  },
  computed: {
    ...mapGetters("JobCreateModule", ["skills", "isFetchingFreqSkills"]),
    subSkills() {
      if (!this.categorySkillId || !this.skills) return [];

      for (let i = 0; i < this.skills.length; i++) {
        if (this.skills[i].id === this.categorySkillId)
          return this.skills[i].children;
      }

      return [];
    },
    hasSelectedSkills() {
      return !_.isEmpty(this.selectedSubSkills);
    }
  },
  watch: {
    selectedSkills(value) {
      if (value) {
        this.bookedSkills = Object.assign({}, value);
        this.selectedSubSkills = this.bookedSkills;
      }
    }
  },
  methods: {
    handleEnterKey(event, skillId) {
      if (event.target.value > 0) {
        this.selectedSubSkills[skillId] = Object.assign(
          {
            qty: event.target.value
          },
          this.getSkillData(skillId)
        );
      }
      this.handleBookButton();
    },
    onSkillChange(val) {
      this.selectedSubSkills = {};
      this.categorySkillId = val;
    },
    handleDeleteBlueprintSkill(data) {
      this.$emit("deleteSkill", data);
    },
    handleBookButton() {
      const bookedSkills = Object.assign(
        this.bookedSkills,
        this.selectedSubSkills
      );
      this.bookedSkills = bookedSkills;
      this.$emit("booked", bookedSkills);
    },
    getSkillData(skillId) {
      for (let i = 0; i < this.skills.length; i++) {
        if (this.skills[i].id === this.categorySkillId) {
          for (let j = 0; j < this.skills[i].children.length; j++) {
            if (parseInt(this.skills[i].children[j].id) === skillId)
              return this.skills[i].children[j];
          }
        }
      }
    },
    onChangeQtySubSkill(event, skillId, updateGlobal) {
      if (event.target.value > 0) {
        this.selectedSubSkills[skillId] = Object.assign(
          {
            qty: event.target.value
          },
          this.getSkillData(skillId)
        );
        if (updateGlobal) {
          this.$emit("updateSkills", _.cloneDeep(this.selectedSubSkills));
        }
      } else {
        delete this.selectedSubSkills[skillId];
      }
    }
  }
};
</script>

<style scoped></style>
