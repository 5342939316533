<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header border-0 pt-5 px-6">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
            >Shift Configuration</span
            >

            <span class="text-muted mt-1 fw-bold fs-7">Fine tune your shifts</span>
          </h3>
        </div>
        <div class="card-body py-3 px-0">
          <div class="row">
            <div class="col-12">
              <ShiftList></ShiftList>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ShiftList from "./ShiftList";
export default defineComponent({
  name: "configure-shifts-card",
  components: {ShiftList},
});
</script>