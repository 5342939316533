<template>
  <div class="row mt-5">
    <div class="col-6">
      <SkillsDropdown @change="onSkillChange"></SkillsDropdown>
      <div class="table-responsive" v-if="categorySkillId">
        <table
          class="table table-row-dashed table-striped table-row-gray-300 align-middle"
        >
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-50px w-175px px-9">Required skill</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subSkill in subSkills" :key="subSkill.id">
              <td class="px-9">
                {{ subSkill.name }}
              </td>
              <td>
                <div
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-control w-75px form-control-sm"
                    type="number"
                    :value="handleExisting(subSkill.id)"
                    @change="onChangeQtySubSkill($event, subSkill.id)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-6">
      <button class="btn btn-success w-100 h-40px" @click="handleBookButton">
        Book
      </button>
      <div class="table-responsive">
        <table
          class="table table-row-dashed table-striped table-row-gray-300 align-middle"
        >
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-50px w-175px px-9">Required skill</th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subSkill in selectedSkills" :key="subSkill.id">
              <td class="px-9">
                {{ subSkill.name }}
              </td>
              <td>
                <div
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-control w-75px form-control-sm"
                    type="number"
                    v-model="subSkill.qty"
                    @change="handleZeroSkillInSingleShift($event, subSkill.id)"
                  />
                </div>
              </td>
              <td>
                <a
                  @click.prevent="handleDeleteSkillFromShift(subSkill)"
                  class="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-1"
                >
                  <DeleteButtonIcon
                    :dynamicClass="'svg-icon-white'"
                    :svgSize="'svg-icon-3'"
                  ></DeleteButtonIcon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-3">
          <div
            class="col-6 d-flex flex-column"
            v-if="$can('job_create_can_edit_pm', 'all')"
          >
            <label class="fs-7 fw-bold mb-1">Crew Handler:</label>
            <el-select
              v-model="details.pm"
              placeholder="Select crew handler"
              size="large"
              clearable
            >
              <el-option
                v-for="item in crewHandlers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-6 d-flex flex-column">
            <label class="fs-7 fw-bold mb-1">Contact on location:</label>
            <el-select
              v-model="details.col"
              :placeholder="
                companyCols.length > 0
                  ? 'Select contact on location'
                  : 'Select a company'
              "
              size="large"
              :disabled="companyCols.length > 0 ? false : true"
              clearable
            >
              <el-option
                v-for="item in companyCols"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6 d-flex flex-column">
            <label class="fs-7 fw-bold mb-1">Dresscode:</label>
            <el-select
              v-model="details.dresscodeID"
              size="large"
              placeholder="Select dresscodes"
              clearable
            >
              <el-option
                v-for="item in dresscodes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-6">
            <label class="fs-7 fw-bold mb-1">Dresscode notes:</label>
            <textarea
              class="fs-6 ps-4 pt-1 w-100 border-secondary rounded-1 border-1"
              placeholder="Enter dresscode notes"
              rows="3"
              v-model.lazy="details.dresscodeNotes"
            ></textarea>
          </div>
        </div>
        <div class="row pe-0">
          <div class="col-6">
            <label class="fs-7 fw-bold mb-1">Notes for workers:</label>
            <textarea
              class="fs-6 ps-4 pt-1 w-100 border-secondary rounded-1 border-1"
              placeholder="Enter worker notes"
              rows="3"
              v-model.lazy="details.workerNotes"
            ></textarea>
          </div>
          <div class="col-6">
            <label class="fs-7 fw-bold mb-1">Shift description:</label>

            <textarea
              class="fs-6 ps-4 pt-1 w-100 border-secondary rounded-1 border-1"
              placeholder="Enter shift description"
              rows="3"
              v-model.lazy="details.description"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SkillsDropdown from "../dropdown/SkillsDropdown";
import { mapActions, mapGetters } from "vuex";
import DeleteButtonIcon from "@/components/Icons/DeleteButton.vue";
import _ from "lodash";

export default {
  name: "box-shift-slots",
  components: { SkillsDropdown, DeleteButtonIcon },
  props: {
    selectedSkills: {
      type: Object,
      required: false
    },
    localShiftID: {
      typer: Number,
      required: true
    }
  },
  data() {
    return {
      categorySkillId: null,
      selectedSubSkills: {},
      bookedSkills: {},
      details: {
        pm: "",
        col: "",
        dresscodeID: "",
        dresscodeNotes: "",
        workerNotes: "",
        description: ""
      }
    };
  },
  mounted() {
    if (this.selectedSkills) {
      this.bookedSkills = Object.assign({}, this.selectedSkills);
      this.selectedSubSkills = this.bookedSkills;
    }
    this.fetchDropdownData();
    const shift = this.shifts.find(shift => shift.id === this.localShiftID);
    if (shift && shift.details) {
      console.log(shift.details)
      this.details = shift.details;
    }
    this.setDetails({
      id: this.localShiftID,
      details: this.details
    });
  },
  computed: {
    ...mapGetters("JobCreateModule", ["shifts"]),
    ...mapGetters("ListsModule", [
      "skills",
      "crewHandlers",
      "dresscodes",
      "companyCols"
    ]),
    subSkills() {
      if (!this.categorySkillId || !this.skills) return [];

      for (let i = 0; i < this.skills.length; i++) {
        if (this.skills[i].id === this.categorySkillId)
          return this.skills[i].children;
      }

      return [];
    },
    hasSelectedSkills() {
      return !_.isEmpty(this.selectedSubSkills);
    }
  },
  watch: {
    selectedSkills: {
      deep: true,
      handler(value) {
        if (value) {
          this.bookedSkills = Object.assign({}, value);
          this.selectedSubSkills = this.bookedSkills;
        }
      }
    },
    details: {
      deep: true,
      handler() {
        this.setDetails({
          id: this.localShiftID,
          details: this.details
        });
      }
    }
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCrewHandlers",  "fetchDresscodes"]),
    ...mapActions("JobCreateModule", [
      "setDetails",
      "deleteSkillFromShift",
      "changeShiftSkillCount",
      "removeZeroSlotSkill"
    ]),
    fetchDropdownData() {
      this.fetchCrewHandlers();
      this.fetchDresscodes();
    },
    handleExisting(skillId) {
      return this.bookedSkills[skillId] !== undefined
        ? this.bookedSkills[skillId].qty
        : "";
    },
    onSkillChange(val) {
      this.selectedSubSkills = {};
      this.categorySkillId = val;
    },
    handleZeroSkillInSingleShift(event, skillId) {
      if (event.target.value <= 0) {
        this.deleteSkillFromShift(skillId);
      }
    },
    handleDeleteSkillFromShift(data) {
      this.deleteSkillFromShift({ skill: data, id: this.localShiftID });
    },
    handleBookButton() {
      const bookedSkills = Object.assign(
        this.bookedSkills,
        this.selectedSubSkills
      );
      this.bookedSkills = bookedSkills;
      this.$emit("booked", bookedSkills);
    },
    getSkillData(skillId) {
      for (let i = 0; i < this.skills.length; i++) {
        if (this.skills[i].id === this.categorySkillId) {
          for (let j = 0; j < this.skills[i].children.length; j++) {
            if (parseInt(this.skills[i].children[j].id) === skillId)
              return this.skills[i].children[j];
          }
        }
      }
    },
    deleteSkillFromShift(skillId) {
      delete this.selectedSubSkills[skillId];
      delete this.bookedSkills[skillId];
      this.$emit(
        "booked",
        Object.assign(this.bookedSkills, this.selectedSubSkills)
      );
    },
    onChangeQtySubSkill(event, skillId) {
      if (event.target.value > 0) {
        this.selectedSubSkills[skillId] = Object.assign(
          {
            qty: event.target.value,
            id: skillId
          },
          this.getSkillData(skillId)
        );
      } else {
        this.deleteSkillFromShift(skillId);
      }
    }
  }
};
</script>

<style scoped>
::placeholder {
  color: #c0c4cc;
}
</style>
