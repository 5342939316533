
/* eslint-disable @typescript-eslint/camelcase */
import _ from "lodash";
import moment from "moment";
import { defineComponent, computed, watch, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "price-estimation-widget",
  setup() {
    const store = useStore();
    const currency = computed(
      () => store.getters["PriceEstimationModule/clientCurrency"]
    );
    const total = computed(() => store.getters["PriceEstimationModule/total"]);
    const priceEstimateQuery = ref(
      store.getters["PriceEstimationModule/query"]
    );
    const estimates = computed(
      () => store.getters["PriceEstimationModule/data"]
    );
    const hasCompanyID = computed(
      () =>
        priceEstimateQuery.value.company_id != null &&
        priceEstimateQuery.value.company_id != ""
    );
    const shifts = computed(() => {
      return hasCompanyID.value ? store.getters["JobCreateModule/shifts"] : {};
    });
    function isValidTime(timeString: string) {
      const regEx = new RegExp("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$");
      return regEx.test(timeString);
    }
    function clearEstimates() {
      store.dispatch("PriceEstimationModule/clearEstimates");
    }
    watch(
      () => [_.cloneDeep(shifts.value), priceEstimateQuery.value],
      () => {
        if (shifts.value.length > 0) {
          const temp = [] as object[];
          for (let i = 0; i < shifts.value.length; i++) {
            const shift = {
              start_time: null as null | string,
              end_time: null as null | string,
              skills: null as null | object[]
            };
            if (
              shifts.value[i].startDate &&
              shifts.value[i].startHour &&
              isValidTime(shifts.value[i].startHour)
            ) {
              shift.start_time = moment(
                `${shifts.value[i].startDate} ${shifts.value[i].startHour}`,
                "D/MM/YYYY HH:mm"
              ).format("D-MM-YYYY HH:mm");
            }
            if (
              shifts.value[i].startDate &&
              shifts.value[i].endHour &&
              isValidTime(shifts.value[i].endHour)
            ) {
              shift.end_time = moment(
                `${shifts.value[i].startDate} ${shifts.value[i].endHour}`,
                "D/MM/YYYY HH:mm"
              ).format("D-MM-YYYY HH:mm");
            }
            if (Object.keys(shifts.value[i].skills).length > 0) {
              const skills = [] as object[];
              Object.keys(shifts.value[i].skills).forEach(key => {
                const skill = {
                  id: shifts.value[i].skills[key].id,
                  positions: shifts.value[i].skills[key].qty
                };
                skills.push(skill);
              });
              shift.skills = skills;
            }
            if (!Object.values(shift).includes(null)) {
              temp.push(shift);
            }
          }
          const payload = {
            ...priceEstimateQuery.value,
            shifts: temp
          };
          if (temp.length > 0) {
            store.dispatch("PriceEstimationModule/fetch", payload);
          } else if (
            !shifts.value.some(e => Object.keys(e.skills).length > 0)
          ) {
            clearEstimates();
          }
        } else {
          clearEstimates();
        }
      },
      { deep: true }
    );
    watch(
      priceEstimateQuery.value,
      () => {
        if (hasCompanyID.value)
          store.dispatch("PriceEstimationModule/fetchClientCurrency", [
            priceEstimateQuery.value
          ]);
      },
      { deep: true }
    );
    return {
      currency,
      total,
      estimates
    };
  }
});
