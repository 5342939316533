<template>
  <el-dialog
    title="Configure shifts"
    @close="closeDialog"
    v-model="dialogVisible"
    width="30%"
  >
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <el-date-picker
            class="input-class mt-3 mt-sm-0 ms-0 col-md-7 col-sm-8 w-50"
            clearable
            :class="error ? 'border border-danger rounded-1' : ''"
            v-model="userSelectedDate"
            type="date"
            size="large"
            placeholder="Select start date"
            format="DD/MM/YYYY"
            value-format="DD/MM/YYYY"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div class="d-flex justify-content-end">
          <el-button type="info" plain @click="closeDialog">Close</el-button>
          <el-button type="success" @click="populateFields" plain
            >Use</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
  <div class="card" v-if="jobTemplates.length">
    <div class="card-body">
      <LoadingSpinner v-if="isLoadingTemplates"></LoadingSpinner>
      <div class="table-responsive" v-else>
        <table class="table table-row-dashed table-row-gray-300">
          <thead>
            <tr class="fw-bolder fs-6 text-gray-800">
              <th>Job Templates</th>
              <th></th>
              <th class="text-end"></th>
            </tr>
          </thead>
          <tbody>
            <JobTemplate
              v-for="template in jobTemplates"
              :key="template.id"
              :template="template"
              @viewTemplateModal="
                data => {
                  $emit('openTemplateModal', data);
                }
              "
              @useTemplate="openTemplateDialog"
            ></JobTemplate>
          </tbody>
        </table>
      </div>
      <Pagination
        :currentPage="pagination.currentPage"
        :totalPages="pagination.lastPage"
        @changeCurrentPage="handlePageChange"
        :maxPages="5"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import JobTemplate from "@/components/jobs/Create/Misc/JobTemplate.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";

export default defineComponent({
  name: "job-templates-card",
  components: {
    LoadingSpinner,
    Pagination,
    JobTemplate
  },
  props: ["companyId"],
  data: () => ({
    pagination: {
      currentPage: 1,
      lastPage: 1
    },
    userSelectedDate: null,
    template: "",
    dialogVisible: false,
    error: false
  }),
  watch: {
    companyId() {
      this.pagination.lastPage = 1;
      this.pagination.currentPage = 1;
      this.fetchTemplates();
    }
  },
  computed: {
    ...mapGetters("JobCreateModule", [
      "jobTemplates",
      "isLoadingTemplates",
      "getPagination"
    ])
  },
  methods: {
    ...mapActions("JobCreateModule", ["changePage", "fetchTemplates"]),
    handlePageChange(page) {
      this.changePage(page);
    },
    closeDialog() {
      this.dialogVisible = false;
      this.userSelectedDate = null;
      this.template = "";
      this.error = false;
    },
    openTemplateDialog(data) {
      this.dialogVisible = true;
      this.template = data;
    },
    populateFields() {
      if (this.userSelectedDate !== null || this.template.shifts.length == 0) {
        this.$emit("templateEvent", {
          ...this.template,
          userSelectedDate: this.userSelectedDate
        });
        this.closeDialog();
      } else {
        this.error = true;
      }
    }
  },
  mounted() {
    this.pagination = this.getPagination;
  }
});
</script>
<style>
@media screen and (min-width: 768px) {
  .job-templates-card {
    min-width: 445px;
  }
}
</style>
