<template>
  <el-dialog
    title="Are you sure ?"
    @close="handleDialogClose"
    v-model="dialogVisible"
    width="30%"
  >
    <div class="d-flex flex-column">
      <span class="fs-5 fw-bold mb-5"
        >This action will overwrite your current shifts!</span
      >
      <div class="d-flex justify-content-end mt-2">
        <el-button @click="handleDialogClose">Cancel</el-button>
        <el-button type="primary" @click="handleDialogConfirm"
          >Confirm</el-button
        >
      </div>
    </div>
  </el-dialog>
  <div class="row mb-7" id="create-shifts-card">
    <div class="col-12 mt-0">
      <div class="card">
        <div class="card-header border-0 pt-5 px-6">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">Create shifts</span>
            <span class="text-muted mt-1 fw-bold fs-7">Set your shifts</span>
          </h3>
        </div>
        <div class="card-body py-3 px-0">
          <div class="row px-6">
            <div class="col-3">
              <label class="required fs-6 fw-bold mb-2">Job Start</label>
              <el-date-picker
                class="input-class ms-0 col-md-7 col-sm-8 w-100"
                clearable
                name="startDate"
                v-model="blueprintNonRef.dateStart"
                size="large"
                type="date"
                placeholder="Select start date"
                format="ddd DD/MM/YYYY"
                value-format="DD/MM/YYYY"
                :disabled-date="disabledDates"
              >
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="startDate" />
                </div>
              </div>
            </div>
            <div class="col-3">
              <label class="fs-6 fw-bold mb-2">Job End</label>
              <el-date-picker
                class="input-class mt-3 mt-sm-0 ms-0 col-md-7 col-sm-8 w-100"
                clearable
                v-model="blueprintNonRef.dateEnd"
                size="large"
                type="date"
                placeholder="Job End"
                :key="endDatePickerKey"
                format="ddd DD/MM/YYYY"
                value-format="DD/MM/YYYY"
                :disabled-date="possibleEndDates"
              >
              </el-date-picker>
            </div>
            <div class="col-2">
              <label class="required fs-6 fw-bold mb-2">Start time</label>
              <div class="start d-flex ali">
                <input
                  v-model="blueprintNonRef.startHour"
                  id="startHour"
                  class="form-control me-2 w-70px h-40px"
                />
              </div>
            </div>
            <div class="col-2">
              <label class="required fs-6 fw-bold mb-2">End time</label>
              <div class="start d-flex ali">
                <input
                  v-model="blueprintNonRef.endHour"
                  id="finishHour"
                  class="form-control me-2 w-70px h-40px"
                />
              </div>
            </div>
          </div>
          <div class="row px-6">
            <div class="col-12">
              <div class="card-header border-0 pt-5 px-0">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder fs-3 mb-1"
                    >Blueprint skills (optional)</span
                  >
                  <span class="text-muted mt-1 fw-bold fs-7"
                    >Heer you can set all the skills which are common between
                    all of your shifts. <br />They will be replicated in each
                    shift</span
                  >
                </h3>
              </div>
              <BoxShiftSlots
                @booked="handleBook"
                @deleteSkill="handleDeleteSkillDialog"
                @updateSkills="data => (this.blueprintNonRef.skills = data)"
              ></BoxShiftSlots>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import BoxShiftSlots from "@/components/jobs/BoxShiftSlots";
import Inputmask from "inputmask";
import { mapActions, mapGetters } from "vuex";
import { disabledDates } from "@/components/jobs/helpers";

export default defineComponent({
  name: "job-view",
  components: { BoxShiftSlots },
  data: () => ({
    dialogVisible: false,
    selectedSkill: "",
    endDatePickerKey: 0,
    blueprintNonRef: {
      dateStart: null,
      dateEnd: null,
      duration: null,
      startHour: null,
      finishHour: null,
      skills: {}
    }
  }),
  mounted() {
    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#startHour");

    Inputmask({
      regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask("#finishHour");
  },
  computed: {
    ...mapGetters("JobCreateModule", ["blueprint", "shifts"]),
    possibleEndDates() {
      return date => {
        if (this.blueprintNonRef.dateStart) {
          return disabledDates(date, this.blueprintNonRef.dateStart);
        }
        return disabledDates(date);
      };
    }
  },
  watch: {
    blueprintNonRef: {
      handler() {
        if (this.blueprintNonRef.dateStart) {
          this.endDatePickerKey++;
        }
        this.setBlueprint(this.blueprintNonRef);
        if (this.blueprint.dateStart && this.blueprint.dateEnd) {
          this.resetShifts();
          this.onPeriodChange();
        } else if (this.blueprint.dateStart) {
          this.resetShifts();
          this.blueprint.dateEnd = this.blueprint.dateStart;
          this.generateSingleShift(this.blueprint);
        }
      },
      deep: true
    }
  },
  methods: {
    disabledDates,
    ...mapActions("JobCreateModule", [
      "addShift",
      "resetShifts",
      "deleteBlueprintSkill",
      "setBlueprint"
    ]),
    generateSingleShift(blueprint) {
      const currentDate = moment(blueprint.dateStart, "DD-MM-YYYY");
      this.addShift({
        id: 0,
        startHour: blueprint.startHour,
        endHour: blueprint.endHour,
        startLabel: moment(currentDate)
          .format("ddd DD/MM/YYYY")
          .toString(),
        startDate: moment(currentDate)
          .format("DD/MM/YYYY")
          .toString(),
        duration: this.blueprint.duration,
        status: !["Sat", "Sun"].includes(moment(currentDate).format("ddd")),
        skills: this.blueprint.skills
      });
    },
    generateShifts(blueprint) {
      const dateArray = {};
      let currentDate = moment(blueprint.dateStart, "DD-MM-YYYY");
      let shiftTempId = 0;
      const stopDate = moment(blueprint.dateEnd, "DD-MM-YYYY");
      while (currentDate <= stopDate) {
        this.addShift({
          id: shiftTempId,
          startHour: blueprint.startHour,
          endHour: blueprint.endHour,
          startLabel: moment(currentDate)
            .format("ddd DD/MM/YYYY")
            .toString(),
          startDate: moment(currentDate)
            .format("DD/MM/YYYY")
            .toString(),
          duration: this.blueprint.duration,
          status: !["Sat", "Sun"].includes(moment(currentDate).format("ddd")),
          skills: this.blueprint.skills
        });
        shiftTempId++;
        currentDate = moment(currentDate).add(1, "days");
      }
      return dateArray;
    },
    onPeriodChange() {
      this.shifts = [];
      this.generateShifts(this.blueprint);
    },
    handleBook(skills) {
      this.blueprintNonRef.skills = skills;
      if (this.blueprint.dateStart && this.blueprint.dateEnd) {
        this.resetShifts();
        this.generateShifts(this.blueprint);
      }
    },
    handleDeleteSkillDialog(event) {
      this.dialogVisible = true;
      this.selectedSkill = event;
    },
    handleDialogConfirm() {
      delete this.blueprintNonRef.skills[this.selectedSkill.id];
      this.handleDialogClose();
    },
    handleDialogClose() {
      this.dialogVisible = false;
      this.selectedSkill = "";
    }
  }
});
</script>

<style scoped>
.duration-separator {
  line-height: 3;
}
</style>
